var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_vm._v("\n  "+_vm._s(_vm.livequote && _vm.livequote.latestPrice
      ? `${
          _vm.livequote.latestSource === "Price close"
            ? `${_vm.utcEpochToLongDate.format(_vm.livequote.latestUpdate)}`
            : `${_vm.epochToOnlyTime_EST.format(_vm.livequote.latestUpdate)}`
        } ${_vm.$Amplify.I18n.get(_vm.livequote.latestSource)}`
      : _vm.ciqData
      ? _vm.parseCiqTime(_vm.ciqData["d"], _vm.$Amplify.I18n.get)
      : "")+"\n")])
}
var staticRenderFns = []

export { render, staticRenderFns }