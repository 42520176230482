import { render, staticRenderFns } from "./WatchlistCard.vue?vue&type=template&id=5b691eb1&scoped=true"
import script from "./WatchlistCard.vue?vue&type=script&lang=js"
export * from "./WatchlistCard.vue?vue&type=script&lang=js"
import style0 from "./WatchlistCard.vue?vue&type=style&index=0&id=5b691eb1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prett_mslvjkx5fqxq5hwkswdqi36zjm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b691eb1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WatchlistTable: require('/vercel/path0/components/watchlist/WatchlistTable.vue').default,WatchlistAggTable: require('/vercel/path0/components/watchlist/WatchlistAggTable.vue').default})
