/**
 *
 * @param {[]} tickers Array containing tickers in watchlist
 * @param {[]} activeColumns Array containing potential columns for watchlist
 * @param {{}} dataObj Object of data by tid: did
 * @param {Boolean} hidePrice Boolean indicating if business mode is activated and all prices should be hidden
 * @param {Function} I18n $Amplify.I18n function with get method that accesses dictionary
 */
const useRecentMultipleAsColumns = (
  tickers,
  activeColumns,
  dataObj,
  hidePrice,
  I18n
) => {
  // FIXME: I don't know why this.list.tickers is not an array sometimes
  // quick fix, you need to figure it out better later
  const tidArr = tickers ? tickers.map((i) => i.tid) : []
  const columnsWithData = activeColumns
    ? activeColumns
        .filter((c) => {
          const toCheck = [
            "tradingMultipleTurns",
            "ntmMcFcf",
            "ntmPE",
            "ntmTevEbitda",
            "ntmTevRev",
            "ntmPFFO",
            "ntmPAFFO",
            "meanTarget",
            "meanNAV",
          ]
          // FIXME: Next line is me going very fast
          const did = c.did || c.denom
          if (toCheck.includes(c.value)) {
            return (
              tidArr.filter((tid) => {
                try {
                  // FIXME: if you're going to show singular values the second condition
                  // needs to be modified
                  // not sure if that is the case with this weird ternary statement?
                  return (
                    dataObj[tid][did] && (c.num ? dataObj[tid][c.num]?.v : true)
                  )
                } catch (error) {
                  return false
                }
              }).length > 0
            )
          } else {
            // check for TEV and marketcap?
            return true
          }
        })
        .map((col) => {
          const text = I18n.get(col.text)
          // FIXME: how to disable changePercent sorting?
          // if (col.value === "changePercent") {
          //   col.sortable = false
          // }
          return { ...col, text }
        })
    : []
  return hidePrice
    ? columnsWithData.filter((f) => {
        const priceToHide = [
          "lastPrice",
          "priceTime",
          "changePercent",
          "val-float-100161",
          "val-float-marketcap",
          "val-float-tev",
        ]
        return !priceToHide.includes(f.value)
      })
    : columnsWithData
}

export default useRecentMultipleAsColumns
