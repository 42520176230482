var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.stateKey === 'watchlist')?_c('b',_vm._g({class:{ watchlistcellgain: _vm.isGain, watchlistcellloss: _vm.isLoss }},on),[_vm._v("\n      "+_vm._s(_vm.latestPrice
          ? _vm.formatPrice.format(_vm.latestPrice)
          : _vm.ciqData && _vm.ciqData["cObj"]
          ? _vm.displayPrice(_vm.ciqData["cObj"], _vm.usd)
          : "-")+"\n    ")]):_c('span',[_vm._v("\n      "+_vm._s(_vm.ciqData && _vm.ciqData["cObj"] ? _vm.displayPrice(_vm.ciqData["cObj"], _vm.usd) : "-")+"\n    ")])]}}])},[_vm._v(" "),_c('span',[_vm._v("\n    "+_vm._s(_vm.latestPrice
        ? "USD"
        : _vm.ciqData && _vm.ciqData["cObj"]
        ? `${_vm.ciqData["cObj"].curr}`
        : "")+"\n  ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',[_vm._v("\n    "+_vm._s(_vm.latestUpdate
        ? `${_vm.epochToOnlyTime_EST.format(_vm.latestUpdate)}`
        : _vm.ciqData && _vm.ciqData["cObj"]
        ? `${_vm.parseCiqTime(_vm.ciqData["cObj"].d)}`
        : "")+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }