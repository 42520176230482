import "core-js/modules/es.array.join.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.timers.js";
import useToFormatWatchlist from "~/functions/useToFormatWatchlist";
import useNumberFormatters from "~/functions/useNumberFormatters";
export default defineComponent({
  props: {
    latestPrice: {
      type: Number,
      "default": 0
    },
    previousClose: {
      type: Number,
      "default": 0
    },
    ciqData: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    tid: {
      type: String,
      "default": undefined
    }
  },
  setup: function setup(props) {
    var _useToFormatWatchlist = useToFormatWatchlist(),
      formatChangeClass = _useToFormatWatchlist.formatChangeClass;
    var _useNumberFormatters = useNumberFormatters(),
      percentChange = _useNumberFormatters.percentChange;
    var isGain = ref(false);
    var isLoss = ref(false);
    watch(function () {
      return props.latestPrice;
    }, function (price, prevPrice) {
      if (price && prevPrice && price !== prevPrice) {
        if (price > prevPrice) {
          isGain.value = true;
          setTimeout(function () {
            isGain.value = false;
          }, 750);
        } else {
          isLoss.value = true;
          setTimeout(function () {
            isLoss.value = false;
          }, 750);
        }
      }
    });
    return {
      formatChangeClass: formatChangeClass,
      percentChange: percentChange,
      isGain: isGain,
      isLoss: isLoss
    };
  },
  computed: {
    cellClass: function cellClass() {
      var classArr = [this.formatChangeClass(this.tid, this.ciqData, this.previousClose, this.latestPrice), this.isGain ? "watchlistcellgain" : "", this.isLoss ? "watchlistcellloss" : ""];
      return classArr.join(" ");
    }
  }
});