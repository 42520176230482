import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.timers.js";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import useNumberFormatters from "~/functions/useNumberFormatters";
export default defineComponent({
  props: {
    ciqData: {
      type: Object,
      "default": undefined
    },
    usd: {
      type: Boolean,
      "default": false
    },
    latestPrice: {
      type: Number,
      "default": 0
    },
    latestUpdate: {
      type: Number,
      "default": 0
    },
    stateKey: {
      type: String,
      "default": "watchlist"
    }
  },
  setup: function setup(props) {
    // set up a watcher on live object? if the value changes trigger the css class?
    var _useDateTimeFormatter = useDateTimeFormatters(),
      parseCiqTime = _useDateTimeFormatter.parseCiqTime,
      epochToOnlyTime_EST = _useDateTimeFormatter.epochToOnlyTime_EST;
    var _useNumberFormatters = useNumberFormatters(),
      displayPrice = _useNumberFormatters.displayPrice,
      formatPrice = _useNumberFormatters.formatPrice;
    var isGain = ref(false);
    var isLoss = ref(false);
    watch(function () {
      return props.latestPrice;
    }, function (price, prevPrice) {
      if (price && prevPrice && price !== prevPrice) {
        if (price > prevPrice) {
          isGain.value = true;
          setTimeout(function () {
            isGain.value = false;
          }, 750);
        } else {
          isLoss.value = true;
          setTimeout(function () {
            isLoss.value = false;
          }, 750);
        }
      }
    });
    return {
      parseCiqTime: parseCiqTime,
      displayPrice: displayPrice,
      formatPrice: formatPrice,
      isGain: isGain,
      isLoss: isLoss,
      epochToOnlyTime_EST: epochToOnlyTime_EST
    };
  }
});