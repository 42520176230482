import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import useBaseUtils from "~/functions/useBaseUtils";
import useBusinessMode from "~/functions/useBusinessMode";
import useRecentMultiplesAsColumns from "~/functions/useRecentMultiplesAsColumns";
import useToFormatWatchlist from "~/functions/useToFormatWatchlist";
import useNumberFormatters from "~/functions/useNumberFormatters";
import useWindow from "~/functions/useWindow";
export default defineComponent({
  props: {
    wltype: {
      type: String,
      "default": "watchlist"
    },
    dense: {
      type: Boolean,
      "default": false
    },
    usd: {
      type: Boolean,
      "default": false
    },
    disablePagination: {
      type: Boolean,
      "default": true
    },
    list: {
      type: Object,
      "default": function _default() {
        return {
          tickers: []
        };
      }
    },
    activetid: {
      type: [String, Number],
      "default": undefined
    },
    showTurns: {
      type: Boolean,
      "default": true
    },
    ciqData: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    quotes: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    activeColumns: {
      type: Array,
      "default": function _default() {
        return [
        // active columns may be a repeat because the
        // parent component should always have a list of columns?
        {
          text: "Ticker",
          columntype: "ts",
          value: "ts",
          width: "60px",
          align: "center"
        }, {
          text: "Last Price",
          columntype: "lastPrice",
          value: "lastPrice",
          width: "70px",
          align: "center"
        }, {
          text: "% Change",
          columntype: "changePercent",
          value: "changePercent",
          // width: "85px",
          align: "center"
        },
        // {
        //   text: "Currency",
        //   columntype: "quoteCurrency",
        //   width: "40px",
        //   align: "center",
        //   sortable: false
        // },
        // {
        //   text: "Market Time",
        //   columntype: "priceTime",
        //   width: "125px",
        //   align: "center",
        //   sortable: false
        // },
        {
          text: "NTM TEV/REV",
          columntype: "tradingMultipleTurns",
          value: "div-turns-tevobj-100180",
          align: "center",
          num: "tevObj",
          denom: "100180"
        }, {
          text: "NTM TEV/EBITDA",
          columntype: "tradingMultipleTurns",
          value: "div-turns-tevobj-100187",
          align: "center",
          num: "tevObj",
          denom: "100187"
        }, {
          text: "NTM P/E",
          columntype: "tradingMultipleTurns",
          value: "div-turns-tevobj-100173",
          align: "center",
          num: "cObj",
          denom: "100173"
        }, {
          text: "NTM MC/FCF",
          columntype: "tradingMultipleTurns",
          value: "div-turns-tevobj-114221",
          align: "center",
          num: "mcObj",
          denom: "114221"
        }, {
          text: "NTM P/FFO",
          columntype: "tradingMultipleTurns",
          value: "div-turns-tevobj-100194",
          align: "center",
          num: "cObj",
          denom: "100194"
        }, {
          text: "NTM P/AFFO",
          columntype: "tradingMultipleTurns",
          value: "div-turns-tevobj-114155",
          align: "center",
          num: "cObj",
          denom: "114155"
        }, {
          text: "Mean Analyst Target",
          columntype: "simpleStreet",
          value: "val-float-100161",
          align: "center",
          did: "100161"
        }, {
          text: "Mean Analyst NAV",
          columntype: "simpleStreet",
          value: "val-float-114210",
          align: "center",
          did: "114210"
        },
        // {
        //   text: "Volume",
        //   columntype: "volume",
        //   align: "center",
        //   // width: "100px"
        // },
        {
          text: "Market Cap",
          columntype: "marketCap",
          value: "val-float-marketcap",
          align: "center" // width: "110px"
        }, {
          text: "TEV",
          columntype: "tev",
          value: "val-float-tev",
          align: "center",
          width: "120px"
        },
        // { text: "YTD %", columntype: "ytdChange", width: "90px", align: "center" },
        // { text: "P/E Ratio", columntype: "peRatio", width: "75px", align: "center" },
        {
          text: "Delete Ticker",
          columntype: "action",
          value: "action",
          sortable: false,
          align: "center"
        }];
      }
    },
    unauth: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup(props) {
    var _useToFormatWatchlist = useToFormatWatchlist(),
      divChangeClass = _useToFormatWatchlist.divChangeClass,
      formatChangeClass = _useToFormatWatchlist.formatChangeClass;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      I18nFn = _useBaseUtils.I18nFn;
    var _useBusinessMode = useBusinessMode(),
      hidePrice = _useBusinessMode.hidePrice;
    var _useNuxtApp = useNuxtApp(),
      $Amplify = _useNuxtApp.$Amplify;
    var _useNumberFormatters = useNumberFormatters(),
      percentChange = _useNumberFormatters.percentChange,
      displayPrice = _useNumberFormatters.displayPrice,
      marketCapUnits = _useNumberFormatters.marketCapUnits,
      calculateSimpleMultiple = _useNumberFormatters.calculateSimpleMultiple;
    var _useWindow = useWindow(props, "WatchlistTable"),
      tableHeight = _useWindow.tableHeight;
    var computedHeaders = computed(function () {
      return useRecentMultiplesAsColumns(props.list.tickers, props.activeColumns, props.ciqData, hidePrice.value, $Amplify.I18n);
    });
    return {
      calculateSimpleMultiple: calculateSimpleMultiple,
      refCode: refCode,
      I18nFn: I18nFn,
      computedHeaders: computedHeaders,
      divChangeClass: divChangeClass,
      formatChangeClass: formatChangeClass,
      percentChange: percentChange,
      displayPrice: displayPrice,
      marketCapUnits: marketCapUnits,
      tableHeight: tableHeight
    };
  },
  data: function data() {
    return {
      compTypeMap: {
        2: "Key Dev",
        3: "Direct Company Contact",
        4: "Named by Third Party",
        5: "Named by Company",
        6: "Named by Competitor",
        1: "Source Filing"
      }
    };
  },
  computed: {
    stateKey: function stateKey() {
      var type = this.wltype === "watchlist" ? "watchlist" : "ciq";
      return type;
    },
    loadingKey: function loadingKey() {
      var key = this.wltype === "watchlist" ? "fetchingCiqPrice" : "fetchingCompetitors";
      return key;
    },
    loading: function loading() {
      // return this.$store.state[this.stateKey][this.loadingKey]
      return this.$store.state.watchlist.fetchingCiqPrice;
    },
    iexLoading: function iexLoading() {
      return this.wltype === "watchlist" ? this.$store.state.iex.lastQuotesLoading : false;
    }
  },
  mounted: function mounted() {
    // TODO: determine which tickers to fetch.
    // watchistLayout mixin has a prop = list {tickers: [{}...]}
    var tickers = this.list.tickers;
    var symbols = tickers.map(function (m) {
      return [m.cid, m.tid];
    });
    this.$store.dispatch("watchlist/fetchCiqPrice", {
      tickers: symbols
    });
  },
  destroyed: function destroyed() {
    if (this.wltype === "watchlist") {
      this.$store.commit("iex/clearFetchInterval", {
        intervalType: "Watchlist"
      });
    }
  },
  methods: {
    deleteTicker: function deleteTicker(ticker) {
      this.$store.dispatch("watchlist/deleteTicker", {
        tid: ticker.tid,
        wid: this.list.id
      });
    }
  }
});