var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{attrs:{"headers":_vm.aggColumns,"items":_vm.aggList,"hide-default-footer":_vm.disablePagination,"disable-pagination":_vm.disablePagination,"dense":_vm.dense},scopedSlots:_vm._u([{key:"item",fn:function({ item, headers }){return [_c('tr',_vm._l((headers),function(header){return _c('td',{key:header.value,staticClass:"text-center"},[(header.value === 'name')?[(item.ts)?[_c('b',{staticClass:"primaryAction--text"},[_vm._v("\n              "+_vm._s(_vm.I18nFn(item.name))+"\n            ")])]:[_c('b',[_vm._v("\n              "+_vm._s(_vm.I18nFn(item.name))+"\n            ")])]]:(item.name === 'Count')?[_vm._v("\n          "+_vm._s(item[header.value])+"\n        ")]:(header.columntype === 'marketCap')?[_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(item[header.value]
                ? _vm.formatMarketCapValue(item[header.value])
                : "-")+"\n          ")])]:(header.columntype === 'tev')?[_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(item[header.value]
                ? _vm.formatMarketCapValue(item[header.value])
                : "-")+"\n          ")])]:(header.columntype === 'tradingMultipleTurns')?[(header.unauth)?[_c('UnauthorizedIcon')]:_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(item[header.value] ? _vm.formatTurnsValue(item[header.value]) : "-")+"\n          ")])]:(header.columntype === 'simpleStreet')?[(header.unauth)?[_c('UnauthorizedIcon')]:_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(item[header.value] ? _vm.formatTurnsValue(item[header.value]) : "-")+"\n          ")])]:(header.columntype === 'changePercent')?[_c('b',{class:item.changePercent >= 0 ? 'green--text' : 'red--text'},[_vm._v("\n            "+_vm._s(item.changePercent > 0
                ? _vm.formatPercent.format(item.changePercent)
                : `(${_vm.formatPercent.format(Math.abs(item.changePercent))})`)+"\n          ")])]:[_vm._v(_vm._s(_vm.I18nFn("error")))]],2)}),0)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }