"use strict"
const lambdaStage = process.env.LAMBDA_STAGE
const devStage = "dev"

export default {
  computed: {
    stage() {
      return lambdaStage
    },
    dev() {
      const stage = lambdaStage
      return stage === devStage
    },
    userAccount() {
      return this.$store.state.user || { attributes: {}, signInUserSession: {} }
    },
    userSession() {
      return (
        this.userAccount.signInUserSession || { accessToken: {}, idToken: {} }
      )
    },
    idtoken() {
      // idToken is what I want to be using
      const idToken = this.userSession.idToken || { payload: {} }
      return idToken
    },
    refCode() {
      return this.idtoken.payload["custom:refCode"]
    },
  },
  // add Dark Mode?
  methods: {
    hasOwnProperty(obj, key) {
      return Object.hasOwn(obj, key)
    },
  },
}
