import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
export default defineComponent({
  props: {
    livequote: {
      type: Object,
      "default": undefined
    },
    ciqData: {
      type: Object,
      "default": undefined
    }
  },
  setup: function setup() {
    var _useDateTimeFormatter = useDateTimeFormatters(),
      parseCiqTime = _useDateTimeFormatter.parseCiqTime,
      epochToOnlyTime_EST = _useDateTimeFormatter.epochToOnlyTime_EST,
      utcEpochToLongDate = _useDateTimeFormatter.utcEpochToLongDate;
    return {
      parseCiqTime: parseCiqTime,
      epochToOnlyTime_EST: epochToOnlyTime_EST,
      utcEpochToLongDate: utcEpochToLongDate
    };
  }
});