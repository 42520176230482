var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.list.tickers,"hide-default-footer":_vm.disablePagination,"disable-pagination":_vm.disablePagination,"dense":_vm.dense,"loading":_vm.loading,"height":_vm.tableHeight,"fixed-header":""},scopedSlots:_vm._u([{key:"item",fn:function({ item, headers }){return [_c('tr',_vm._l((headers),function(header){return _c('td',{key:header.value,staticClass:"text-center"},[(header.value === 'ts')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('nuxt-link',{class:{
                  'accent--text': _vm.$vuetify.theme.dark,
                  'primaryAction--text': _vm.activetid === item.tid,
                },attrs:{"to":`/stock/about?cid=${item.cid}&tid=${item.tid}&ref=${_vm.refCode}`}},[_c('b',_vm._g({},on),[_vm._v("\n                  "+_vm._s(item.ts)+"\n                ")])])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(item.name))])])]:(header.columntype === 'name')?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('nuxt-link',_vm._g({class:{
                  'accent--text': _vm.$vuetify.theme.dark,
                  'primaryAction--text': _vm.activetid === item.tid,
                },attrs:{"to":`/stock/about?cid=${item.cid}&tid=${item.tid}&ref=${_vm.refCode}`}},on),[_vm._v("\n                "+_vm._s(item.name)+"\n              ")])]}}],null,true)})]:(header.columntype === 'fav')?_c('FavButton',{attrs:{"ticker":item,"small":true}}):(header.columntype === 'action')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteTicker(item)}}},[_vm._v("\n          delete\n        ")]):(header.columntype === 'compid')?[_vm._v("\n          "+_vm._s(_vm.compTypeMap[item.compid])+"\n        ")]:(header.columntype === 'tStatus')?[_vm._v("\n          "+_vm._s(item.tstatus)+"\n        ")]:[(!_vm.ciqData.hasOwnProperty(item.tid) && _vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table-cell"}}):(header.columntype === 'lastPrice')?[(_vm.iexLoading)?_c('v-skeleton-loader',{attrs:{"type":"table-cell"}}):[(_vm.quotes[item.tid])?_c('WatchlistVuetifyLastPrice',{attrs:{"usd":_vm.usd,"livequote":_vm.quotes[item.tid],"latest-price":_vm.quotes[item.tid].latestPrice,"latest-update":_vm.quotes[item.tid].latestUpdate,"ciq-data":_vm.ciqData[item.tid],"state-key":_vm.stateKey}}):_c('WatchlistVuetifyLastPrice',{attrs:{"usd":_vm.usd,"ciq-data":_vm.ciqData[item.tid],"state-key":_vm.stateKey}})]]:(header.columntype === 'changePercent')?[(_vm.iexLoading)?_c('v-skeleton-loader',{attrs:{"type":"table-cell"}}):[(_vm.quotes[item.tid])?_c('WatchlistVuetifyChangePercent',{attrs:{"tid":item.tid,"latest-price":_vm.quotes[item.tid].latestPrice,"previous-close":_vm.quotes[item.tid].previousClose,"ciq-data":_vm.ciqData}}):_c('WatchlistVuetifyChangePercent',{attrs:{"tid":item.tid,"ciq-data":_vm.ciqData}})]]:(header.columntype === 'tradingMultipleTurns')?[(
                _vm.ciqData[item.tid] &&
                _vm.ciqData[item.tid]['c'] &&
                _vm.ciqData[item.tid][header.denom] &&
                _vm.ciqData[item.tid][header.denom].unauth
              )?[_c('UnauthorizedIcon')]:_c('span',{class:_vm.divChangeClass(item.tid, _vm.ciqData, header.num, header.denom)},[_vm._v("\n              "+_vm._s(_vm.ciqData[item.tid] &&
                _vm.ciqData[item.tid]["c"] &&
                _vm.ciqData[item.tid][header.denom]
                  ? _vm.calculateSimpleMultiple(
                      _vm.ciqData[item.tid][header.num],
                      _vm.ciqData[item.tid][header.denom],
                      { format: true, showTurns: _vm.showTurns }
                    )
                  : "-")+"\n            ")])]:(header.columntype === 'priceTime')?_c('WatchlistVuetifyPriceTime',{attrs:{"livequote":_vm.quotes[item.tid],"ciq-data":_vm.ciqData[item.tid]}}):(header.columntype === 'quoteCurrency')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v("\n                  "+_vm._s(_vm.ciqData[item.tid] ? _vm.ciqData[item.tid]["qiso"] : "")+"\n                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(_vm.ciqData[item.tid] ? _vm.ciqData[item.tid]["qcurr"] : "-")+"\n              ")])])]:(header.columntype === 'simpleStreet')?[(
                _vm.ciqData[item.tid] &&
                _vm.ciqData[item.tid][header.did] &&
                _vm.ciqData[item.tid][header.did].unauth
              )?[_c('UnauthorizedIcon')]:(_vm.stateKey === 'watchlist')?_c('b',[_vm._v("\n              "+_vm._s(_vm.ciqData[item.tid] && _vm.ciqData[item.tid][header.did]
                  ? _vm.displayPrice(_vm.ciqData[item.tid][header.did], _vm.usd)
                  : "-")+"\n            ")]):_c('span',[_vm._v("\n              "+_vm._s(_vm.ciqData[item.tid] && _vm.ciqData[item.tid][header.did]
                  ? _vm.displayPrice(_vm.ciqData[item.tid][header.did], _vm.usd)
                  : "-")+"\n            ")])]:(header.columntype === 'marketCap')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v("\n                  "+_vm._s(_vm.ciqData[item.tid] && _vm.ciqData[item.tid]["mc"]
                      ? _vm.marketCapUnits(_vm.ciqData[item.tid], _vm.usd)
                      : "-")+"\n                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(_vm.ciqData[item.tid] ? _vm.ciqData[item.tid]["mccurr"] : "-")+"\n              ")])])]:(header.columntype === 'tev')?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_vm._v("\n                  "+_vm._s(_vm.ciqData[item.tid] && _vm.ciqData[item.tid]["tev"]
                      ? _vm.marketCapUnits(_vm.ciqData[item.tid], _vm.usd, "tev")
                      : "-")+"\n                ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                "+_vm._s(_vm.ciqData[item.tid] ? _vm.ciqData[item.tid]["mccurr"] : "-")+"\n              ")])])]:(header.columntype === 'volume')?[_vm._v("\n            "+_vm._s(_vm.ciqData[item.tid] && _vm.ciqData[item.tid]["v"]
                ? _vm.volumeUnits(_vm.ciqData[item.tid]["v"])
                : "-")+"\n          ")]:[_vm._v(_vm._s(header))]]],2)}),0)]}},{key:"no-data",fn:function(){return [(_vm.wltype === 'watchlist')?_c('p',[_vm._v("\n      "+_vm._s(_vm.$Amplify.I18n.get("Add Tickers to active watchlist by clicking on"))+"\n      "),_c('v-icon',{attrs:{"outlined":""}},[_vm._v("favorite")]),_vm._v("\n      "+_vm._s(_vm.I18nFn("next to company name"))+"\n    ")],1):_vm._e(),_vm._v(" "),(_vm.wltype === 'competitors')?_c('p',[_vm._v("\n      "+_vm._s(_vm.$Amplify.I18n.get(
          "Sorry - no listed competitors in this industry. Try selecting a different industry"
        ))+"\n    ")]):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }