const useToFormatWatchlist = () => {
  // divChangeClass Method
  const divChangeClass = (tid, dataObj, numerator, denominator) => {
    const data = dataObj[tid]
    if (
      data?.[numerator] &&
      data[denominator] &&
      data[numerator].v &&
      data[denominator].v
    ) {
      const product = data[numerator].v * data[denominator].v
      if (typeof product === "number" && product < 0) {
        return "red--text"
      } else {
        return ""
      }
    }
  }
  const formatChangeClass = (tid, data, previousClose, latestPrice) => {
    try {
      let y0 = 0
      let y1 = 0
      if (previousClose && latestPrice) {
        y0 = previousClose
        y1 = latestPrice
      } else if (data[tid]) {
        y0 = data[tid].o
        y1 = data[tid].c
      }
      if (y0 && y1) {
        const v0 = parseFloat(y0)
        const v1 = parseFloat(y1)
        return v0 <= v1 ? "green--text" : "red--text"
      }
    } catch (error) {}
  }

  // change class for number based on value
  const displayRedWhenValNegative = (val) => {
    if (val && val < 0) {
      return "red--text"
    } else {
      return ""
    }
  }
  return {
    divChangeClass,
    displayRedWhenValNegative,
    formatChangeClass,
  }
}

export default useToFormatWatchlist
