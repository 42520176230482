import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import WatchlistCard from "~/components/watchlist/WatchlistCard.vue";
export default defineComponent({
  components: {
    WatchlistCard: WatchlistCard
  },
  data: function data() {
    return {
      newList: false,
      activeTab: null,
      watchlists: [{
        title: "Secular Growers",
        descriptions: "Secular Growers",
        id: "secularGrowers",
        stocks: {
          items: [{
            symbol: "GOOG"
          }, {
            symbol: "AMZN"
          }, {
            symbol: "MSFT"
          }, {
            symbol: "ADBE"
          }, {
            symbol: "NFLX"
          }, {
            symbol: "FB"
          }, {
            symbol: "AAPL"
          }, {
            symbol: "V"
          }, {
            symbol: "MA"
          }, {
            symbol: "EXPE"
          }, {
            symbol: "BKNG"
          }, {
            symbol: "NOW"
          }, {
            symbol: "WDAY"
          }, {
            symbol: "CRM"
          }, {
            symbol: "ILMN"
          }]
        }
      }, {
        title: "Secularly Challenged Stocks",
        descriptions: "Secularly Challenged Stocks",
        id: "secularlyChallenged",
        stocks: {
          items: [{
            symbol: "GPS"
          }, {
            symbol: "KSS"
          }, {
            symbol: "BBBY"
          }, {
            symbol: "M"
          }, {
            symbol: "TGT"
          }, {
            symbol: "WSM"
          }, {
            symbol: "DSW"
          }, {
            symbol: "K"
          }, {
            symbol: "KMB"
          }, {
            symbol: "PG"
          }, {
            symbol: "HSIC"
          }, {
            symbol: "CHRW"
          }, {
            symbol: "WU"
          }, {
            symbol: "ANF"
          }, {
            symbol: "OMC"
          }, {
            symbol: "JNPR"
          }, {
            symbol: "AYI"
          }]
        }
      }, {
        title: "Trending",
        description: "Most researched on TIKR",
        id: "trendingId",
        stocks: {
          items: []
        }
      }]
    };
  },
  computed: {
    quotes: function quotes() {
      var _this = this;
      var watchlists = this.watchlists;
      watchlists.map(function (list) {
        return list.stocks.items.map(function (stock) {
          return _this.$store.state.watchlist.quotes[stock.symbol];
        });
      });
      return this.$store.state.watchlist.quotes;
    },
    fetchingQuotes: function fetchingQuotes() {
      return this.$store.state.watchlist.fetchingQuotes;
    }
  },
  created: function created() {
    // When watchlists is created/mounted, fetch the lists
    var initSymbols = this.watchlists.reduce(function (acc, list) {
      return [].concat(_toConsumableArray(list.stocks.items.map(function (stock) {
        return stock.symbol;
      })), _toConsumableArray(acc));
    }, []);
    var symbols = _toConsumableArray(new Set(initSymbols));
    this.$store.dispatch("watchlist/fetchQuotes", {
      symbols: symbols
    });
    if (this.$route.query.id) {
      this.activeTab = this.$route.query.id;
    }
  },
  methods: {
    fetchQuotes: function fetchQuotes(watchlist) {
      // console.log("fetch quotes called for: ", watchlist.stocks.items)
      var symbols = watchlist.stocks.items.map(function (symbol) {
        return symbol.symbol;
      });
      if (symbols.length > 0) {
        this.$store.dispatch("watchlist/fetchQuotes", {
          symbols: symbols
        });
      }
    },
    closeAddWatchlist: function closeAddWatchlist(id) {
      // pass the id with $emit(newId)
      // should this be coming from the component or from the store?
      // I suppose it makes sense to do it all in the component, less connections
      console.log("close add watchlist called with: ", id);
      this.newList = !this.newList;
      if (id) {
        this.activeTab = id;
      }
    }
  }
});