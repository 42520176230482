import { useWindowSize } from "@vueuse/core";
var useWindow = function useWindow(props, type) {
  var _useWindowSize = useWindowSize(),
    height = _useWindowSize.height;
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var tableHeight = computed(function () {
    try {
      var innerHeight = height.value;
      var maxHeight = innerHeight < 450 ? innerHeight - 25 : innerHeight - 200;
      var totalRowHeight = 0;
      if (type === "WatchlistTable") {
        maxHeight = innerHeight < 450 ? innerHeight - 25 : innerHeight - 350;
        totalRowHeight = props.list.tickers.length * 32; // + 64
      } else if (type === "ScreenerResultsTable") {
        maxHeight = innerHeight < 450 ? innerHeight - 25 : innerHeight - 350;
        var dataKey = "itemsPerPage";
        var storeObj = $store.state.screener.screenDataObj[props.screenerId];
        var dataObj = storeObj[dataKey];
        var itemsPerPage = dataObj || 25;
        var selectedHeight = itemsPerPage * 32;
        totalRowHeight = props.results.rows.length * 32;
        totalRowHeight = selectedHeight < totalRowHeight ? selectedHeight : totalRowHeight;
      } else if (type === "CiqTranscriptTable") {
        totalRowHeight = Math.min(props.inititemsperpage, props.transcripts.length) * 32; // + 64
      } else if (type === "TrkdFilingsTable") {
        totalRowHeight = Math.min(props.initperpage, props.allfilings.length) * 32; // + 64
      }
      return totalRowHeight <= maxHeight ? undefined : maxHeight;
    } catch (e) {
      return undefined;
    }
  });
  return {
    tableHeight: tableHeight
  };
};
export default useWindow;