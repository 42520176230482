import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import debounce from "lodash/debounce";
import WatchlistTable from "./WatchlistTable.vue";
import algoliaSearch from "~/mixins/algoliaSearch";
import baseUtils from "~/mixins/baseUtils";
import WatchlistAggTable from "~/components/watchlist/WatchlistAggTable.vue";
import watchlistColumns from "~/utils/templates/baseWatchlistColumns.json";
import glossary from "~/utils/templates/glossary";
import useNumberFormatters from "~/functions/useNumberFormatters";
import useAnalytics from "~/functions/useAnalytics";
import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  components: {
    WatchlistTable: WatchlistTable,
    WatchlistAggTable: WatchlistAggTable
  },
  mixins: [baseUtils, algoliaSearch()],
  props: {
    list: {
      type: Object,
      "default": function _default() {
        return {
          tickers: []
        };
      }
    },
    unauth: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup() {
    var _useNumberFormatters = useNumberFormatters(),
      calculateSimpleMultiple = _useNumberFormatters.calculateSimpleMultiple;
    var _useAnalytics = useAnalytics(),
      events = _useAnalytics.events,
      sendEvent = _useAnalytics.sendEvent;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    return {
      calculateSimpleMultiple: calculateSimpleMultiple,
      events: events,
      sendEvent: sendEvent,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      // autocompleteModel: null,
      showTurns: true,
      titleError: null,
      deleteTickerObj: {
        text: "Delete Ticker",
        columntype: "action",
        value: "action",
        sortable: false,
        align: "center"
      },
      defaultColumns: [
      // default columns are the default columns that show up in a watchlist
      // when a watchlist contains no columns
      {
        text: "Ticker",
        columntype: "ts",
        value: "ts",
        width: "60px",
        align: "center"
      }, {
        text: "Last Price",
        columntype: "lastPrice",
        value: "lastPrice",
        width: "70px",
        align: "center"
      }, {
        text: "% Change",
        columntype: "changePercent",
        value: "changePercent",
        // width: "85px",
        align: "center"
      },
      // {
      //   text: "Company Name",
      //   columntype: "ts",
      //   value: "name",
      //   align: "center",
      // },
      // {
      //   text: "Currency",
      //   columntype: "quoteCurrency",
      //   width: "40px",
      //   align: "center",
      //   sortable: false
      // },
      // {
      //   text: "Market Time",
      //   columntype: "priceTime",
      //   width: "125px",
      //   align: "center",
      //   sortable: false
      // },
      {
        text: "NTM TEV/REV",
        columntype: "tradingMultipleTurns",
        value: "div-turns-tevobj-100180",
        align: "center",
        num: "tevObj",
        denom: "100180"
      }, {
        text: "NTM TEV/EBITDA",
        columntype: "tradingMultipleTurns",
        value: "div-turns-tevobj-100187",
        align: "center",
        num: "tevObj",
        denom: "100187"
      }, {
        text: "NTM P/E",
        columntype: "tradingMultipleTurns",
        value: "div-turns-tevobj-100173",
        align: "center",
        num: "cObj",
        denom: "100173"
      }, {
        text: "NTM MC/FCF",
        columntype: "tradingMultipleTurns",
        value: "div-turns-tevobj-114221",
        align: "center",
        num: "mcObj",
        denom: "114221"
      }, {
        text: "NTM P/FFO",
        columntype: "tradingMultipleTurns",
        value: "div-turns-tevobj-100194",
        align: "center",
        num: "cObj",
        denom: "100194"
      }, {
        text: "NTM P/AFFO",
        columntype: "tradingMultipleTurns",
        value: "div-turns-tevobj-114155",
        align: "center",
        num: "cObj",
        denom: "114155"
      },
      // extra ntm objects below... for safekeeping
      // {
      //   text: "NTM P/ GAAP E",
      //   columntype: "tradingMultipleTurns",
      //   value: "div-turns-tevobj-21635",
      //   align: "center",
      //   num: "cObj",
      //   denom: "21635",
      // },
      // {
      //   text: "NTM MC/DCPS",
      //   columntype: "tradingMultipleTurns",
      //   value: "div-turns-tevobj-105076",
      //   align: "center",
      //   num: "mcObj",
      //   denom: "105076",
      // },
      // {
      //   text: "NTM P/BV",
      //   columntype: "tradingMultipleTurns",
      //   value: "div-turns-tevobj-104055",
      //   align: "center",
      //   num: "cObj",
      //   denom: "104055",
      // },
      // {
      //   text: "NTM P/NAV",
      //   columntype: "tradingMultipleTurns",
      //   value: "div-turns-tevobj-105316",
      //   align: "center",
      //   num: "cObj",
      //   denom: "105316",
      // },
      {
        text: "Mean Analyst Target",
        columntype: "simpleStreet",
        value: "val-float-100161",
        align: "center",
        did: "100161"
      }, {
        text: "Mean Analyst NAV",
        columntype: "simpleStreet",
        value: "val-float-114210",
        align: "center",
        did: "114210"
      },
      // {
      //   text: "Volume",
      //   columntype: "volume",
      //   align: "center",
      //   // width: "100px"
      // },
      {
        text: "Market Cap",
        columntype: "marketCap",
        value: "val-float-marketcap",
        align: "center" // width: "110px"
      }, {
        text: "TEV",
        columntype: "tev",
        value: "val-float-tev",
        align: "center",
        width: "120px"
      } // { text: "YTD %", columntype: "ytdChange", width: "90px", align: "center" },
      // { text: "P/E Ratio", columntype: "peRatio", width: "75px", align: "center" },
      // {
      //   text: "Delete Ticker",
      //   columntype: "action",
      //   value: "action",
      //   sortable: false,
      //   align: "center",
      // },
      ],
      glossary: glossary
    };
  },
  computed: {
    data: function data() {
      return this.$store.state.watchlist.ciq || {};
    },
    quotes: function quotes() {
      // return this.$store.state.watchlist.quotes || {}
      return this.$store.state.iex.lastQuotes || {};
    },
    tickerLimit: function tickerLimit() {
      return this.$store.state.watchlist.maxTickers > this.list.tickers.length;
    },
    transformedList: function transformedList() {
      var _this = this;
      var origList = this.list;
      return _objectSpread(_objectSpread({}, origList), {}, {
        tickers: origList.tickers.map(function (m) {
          var sortObj = {};
          if (_this.data[m.tid]) {
            sortObj = _this.transformWatchlistForSort(m, _this.data[m.tid]);
          }
          return _objectSpread(_objectSpread({}, m), sortObj);
        })
      });
    },
    columns: {
      get: function get() {
        if (this.list.columns && this.list.columns.length > 0) {
          return this.list.columns; // .map((c) => {
          //   c.text = this.I18nFn(c.text)
          //   return c
          // })
        } else {
          // const payload = {
          //   id: this.list.id,
          //   columns: this.defaultColumns,
          // }
          // this.$store.dispatch("watchlist/updateWatchlistColumns", payload)
          return this.defaultColumns; // .map((c) => {
          //   c.text = this.I18nFn(c.text)
          //   return c
          // })
        }
      },
      set: function set(e) {
        var payload = {
          id: this.list.id,
          columns: e
        }; // FIXME: with translations this is saving the text
        // I need to flip it back to english before saving
        this.$store.dispatch("watchlist/updateWatchlistColumns", payload);
      }
    },
    availableColumns: function availableColumns() {
      return watchlistColumns;
    }
  },
  created: function created() {
    this.debounceUpdateTitle = debounce(this.updateTitle, 750);
    this.debounceUpdateDescription = debounce(this.updateDescription, 1200);
    if (!this.list.id) {
      console.error("Error loading watchlist component - list prop requires an id");
    }
  },
  methods: {
    transformWatchlistForSort: function transformWatchlistForSort(ticker, tickerData) {
      var _this2 = this; // tickerdata is the object result from transformWatchlistResponse
      var sortObj = {};
      sortObj.lastPrice = this.watchlistPrice(tickerData.cObj);
      sortObj.changePercent = this.percentChange(tickerData);
      sortObj.volume = tickerData.v ? Number(tickerData.v) : undefined;
      watchlistColumns.filter(function (f) {
        return f.value && f.value.split("-")[0] === "div";
      }).forEach(function (header) {
        var _tickerData$denom;
        var value = header.value,
          num = header.num,
          denom = header.denom;
        if ((_tickerData$denom = tickerData[denom]) !== null && _tickerData$denom !== void 0 && _tickerData$denom.unauth) {
          sortObj[value] = "unauth";
        } else {
          sortObj[value] = _this2.calculateSimpleMultiple(tickerData[num], tickerData[denom]);
        }
      }); // sortObj.ntmTevRev = this.simpleMultipleCalc(
      //   tickerData["tevObj"],
      //   tickerData["100180"]
      // )
      // sortObj.ntmTevEbitda = this.simpleMultipleCalc(
      //   tickerData["tevObj"],
      //   tickerData["100187"]
      // )
      // sortObj.ntmPE = this.simpleMultipleCalc(
      //   tickerData["cObj"],
      //   tickerData["100173"]
      // )
      // sortObj.ntmMcFcf = this.simpleMultipleCalc(
      //   tickerData["mcObj"],
      //   tickerData["114221"]
      // )
      // sortObj.ntmPFFO = this.simpleMultipleCalc(
      //   tickerData["cObj"],
      //   tickerData["100194"]
      // )
      // sortObj.ntmPAFFO = this.simpleMultipleCalc(
      //   tickerData["cObj"],
      //   tickerData["114155"]
      // )
      sortObj["val-float-100161"] = this.watchlistPrice(tickerData["100161"]);
      sortObj["val-float-114210"] = this.watchlistPrice(tickerData["114210"]);
      sortObj["val-float-marketcap"] = this.watchlistPrice(tickerData.mcObj);
      sortObj["val-float-tev"] = this.watchlistPrice(tickerData.tevObj);
      return sortObj;
    },
    percentChange: function percentChange() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {}; // y0 is the value of interest at t0, y1 is the value of interest at t1
      // where t0 < t1 thereform %change = (y1 - y0) / y0
      // Also assuming that y0 and y1 are numbers stored as strings so they need
      // to be parsed in order to do the math
      try {
        var y0 = data.o;
        var y1 = data.c;
        if (y0) {
          var v0 = parseFloat(y0);
          var v1 = parseFloat(y1);
          var delta = v1 - v0;
          var percentChange = delta / v0;
          return percentChange;
        }
      } catch (error) {
        this.transformError(error);
      }
    },
    watchlistPrice: function watchlistPrice() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var valueKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "v";
      var conversionKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "pc";
      try {
        var toUSD = data[conversionKey] || 1;
        var closePrice = data[valueKey];
        if (data.unauth) {
          return "unauth";
        }
        if (closePrice) {
          return closePrice / toUSD;
        }
      } catch (error) {
        this.transformError(error);
      }
    },
    copyTable: function copyTable() {
      var _this3 = this;
      var tableidx = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      try {
        this.showTurns = false;
        this.$nextTick(function () {
          var table = document.querySelectorAll("table")[tableidx];
          var range = document.createRange();
          var sel = window.getSelection();
          sel.removeAllRanges();
          range.selectNode(table);
          sel.addRange(range);
          document.execCommand("copy");
          _this3.showTurns = true;
        });
      } catch (error) {
        console.error("Error copying watchlist Table idx: ".concat(tableidx), error);
      }
    },
    setActiveWatchlist: function setActiveWatchlist() {
      this.$store.dispatch("watchlist/setActiveWatchlist", {
        watchlist: this.list
      });
    },
    addTicker: function addTicker(ticker) {
      var maxTickers = this.$store.state.watchlist.maxTickers;
      var tickerExists = this.list.tickers.filter(function (t) {
        return t.tid === (ticker === null || ticker === void 0 ? void 0 : ticker.tradingitemid.toString());
      }); // check if the ticker is already on the list... this.list.tickers.filter(t => t.tid === ticker.tradingitemid).length > 0
      if (tickerExists.length > 0) {
        confirm("".concat(this.I18nFn("Ticker"), " ").concat(ticker.tickersymbol, " ").concat(this.I18nFn("from exchange"), " ").concat(ticker.exchangesymbol, " ").concat(this.I18nFn("already exists on this watchlist!")));
        return;
      }
      if (ticker) {
        if (this.tickerLimit) {
          this.sendEvent(this.events.addTickerToWatchlist, _objectSpread({
            event: "addTickerToWatchlist"
          }, ticker));
          this.$store.dispatch("watchlist/addTicker", {
            wid: this.list.id,
            ticker: ticker
          });
        } else {
          confirm("".concat(this.I18nFn("Error: watchlist is full - cannot add more than"), " ").concat(maxTickers, "\n            ").concat(this.I18nFn("Please Create a new watchlist")));
        }
      }
      this.autocompleteModel = null;
    },
    deleteTicker: function deleteTicker(ticker) {
      this.$store.dispatch("watchlist/deleteTicker", {
        tid: ticker.tid,
        wid: this.list.id
      });
    },
    updateTitle: function updateTitle(e) {
      if (e.length === 0) {
        // not allowed, set an error that can be displayed
        this.titleError = this.I18nFn("Error: Watchlist must have a title");
      } else {
        this.titleError = null;
        var payload = {
          id: this.list.id,
          title: e
        };
        this.$store.dispatch("watchlist/updateWatchlist", payload);
      }
    },
    updateDescription: function updateDescription(e) {
      var payload = {
        id: this.list.id,
        description: e
      };
      this.$store.dispatch("watchlist/updateWatchlist", payload);
    },
    removeColumn: function removeColumn(e) {
      var newColumns = this.columns.filter(function (f) {
        return f.value !== e.value;
      });
      this.columns = newColumns;
    },
    removeWatchlist: function removeWatchlist() {
      confirm(this.I18nFn("Are you sure you want to delete this watchlist?")) && this.$store.dispatch("watchlist/deleteWatchlist", {
        wid: this.list.id
      }) && this.sendEvent(this.events.deleteWatchlist, {
        event: "deleteWatchList"
      }) && this.$router.push({
        path: "/watchlist",
        query: {
          ref: this.refCode
        }
      });
    },
    transformError: function transformError(error) {
      if (this.dev) {
        console.error("error transforming watchlist data: ", error);
      }
    }
  }
});