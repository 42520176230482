import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import _mean from "lodash/mean";
import _max from "lodash/max";
import _min from "lodash/min";
import { deviation, median as _median } from "d3";
import useBusinessMode from "~/functions/useBusinessMode";
import useBaseUtils from "~/functions/useBaseUtils";
import useToFormatWatchlist from "~/functions/useToFormatWatchlist";
import useNumberFormatters from "~/functions/useNumberFormatters";
export default defineComponent({
  props: {
    list: {
      type: Object,
      "default": function _default() {
        return {
          tickers: []
        };
      }
    },
    activeCompObj: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    ciqData: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    baseAggList: {
      type: Array,
      "default": function _default() {
        return [{
          name: "Mean"
        }, {
          name: "Median"
        }, {
          name: "High"
        }, {
          name: "Low"
        }, {
          name: "Standard Deviation"
        }, {
          name: "Count"
        }];
      }
    },
    activeColumns: {
      type: Array,
      "default": function _default() {
        return [{
          text: "NTM TEV/REV",
          columntype: "tradingMultipleTurns",
          value: "div-turns-tevobj-100180",
          align: "center",
          num: "tevObj",
          denom: "100180"
        }, {
          text: "NTM TEV/EBITDA",
          columntype: "tradingMultipleTurns",
          value: "div-turns-tevobj-100187",
          align: "center",
          num: "tevObj",
          denom: "100187"
        }, {
          text: "NTM P/E",
          columntype: "tradingMultipleTurns",
          value: "div-turns-tevobj-100173",
          align: "center",
          num: "cObj",
          denom: "100173"
        }, {
          text: "NTM MC/FCF",
          columntype: "tradingMultipleTurns",
          value: "div-turns-tevobj-114221",
          align: "center",
          num: "mcObj",
          denom: "114221"
        }, {
          text: "NTM P/FFO",
          columntype: "tradingMultipleTurns",
          value: "div-turns-tevobj-100194",
          align: "center",
          num: "cObj",
          denom: "100194"
        }, {
          text: "NTM P/AFFO",
          columntype: "tradingMultipleTurns",
          value: "div-turns-tevobj-114155",
          align: "center",
          num: "cObj",
          denom: "114155"
        }, {
          text: "Market Cap",
          columntype: "marketCap",
          value: "val-float-marketcap",
          align: "center" // width: "110px"
        }, {
          text: "TEV",
          columntype: "tev",
          value: "val-float-tev",
          align: "center",
          width: "120px"
        }];
      }
    },
    showTurns: {
      type: Boolean,
      "default": true
    },
    dense: {
      type: Boolean,
      "default": false
    },
    disablePagination: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup() {
    var _useToFormatWatchlist = useToFormatWatchlist(),
      displayRedWhenValNegative = _useToFormatWatchlist.displayRedWhenValNegative;
    var _useBusinessMode = useBusinessMode(),
      hidePrice = _useBusinessMode.hidePrice;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      I18nFn = _useBaseUtils.I18nFn;
    var _useNumberFormatters = useNumberFormatters(),
      formatTurnsValue = _useNumberFormatters.formatTurnsValue,
      formatPrice = _useNumberFormatters.formatPrice,
      formatPercent = _useNumberFormatters.formatPercent,
      formatMarketCapValue = _useNumberFormatters.formatMarketCapValue;
    return {
      hidePrice: hidePrice,
      refCode: refCode,
      I18nFn: I18nFn,
      displayRedWhenValNegative: displayRedWhenValNegative,
      formatTurnsValue: formatTurnsValue,
      formatPrice: formatPrice,
      formatPercent: formatPercent,
      formatMarketCapValue: formatMarketCapValue
    };
  },
  computed: {
    aggColumns: function aggColumns() {
      var _this = this; // used to be computed headers
      var tidArr = this.list.tickers ? this.list.tickers.map(function (i) {
        return i.tid;
      }) : [];
      var dataObj = this.ciqData;
      var columnsToRemove = ["ts", "name",
      // FIXME: this is a janky way to remove company name from agg table
      "lastPrice", "action", "priceTime", "quoteCurrency", "val-float-100161", "val-float-114210"];
      var columnsToShow = this.activeColumns.filter(function (f) {
        return !columnsToRemove.includes(f.value);
      }).map(function (col) {
        var text = _this.I18nFn(col.text);
        return _objectSpread(_objectSpread({}, col), {}, {
          text: text
        });
      });
      var firstAggCol = [{
        text: this.I18nFn("Summary Statistics"),
        value: "name",
        width: "140px",
        align: "center"
      }];
      var allAggColumns = firstAggCol.concat(columnsToShow);
      var marketCapValues = ["val-float-marketcap", "val-float-tev"];
      var columnsWithData = allAggColumns.reduce(function (acc, c) {
        var did = c.did || c.denom;
        var push = false;
        var unauth = false;
        if (c.value && c.value.split("-").length > 1) {
          push = tidArr.filter(function (tid) {
            try {
              // FIXME: if you're going to show singular values the second condition
              // needs to be modified
              // not sure if that is the case with this weird ternary statement?
              if (dataObj[tid][did]) {
                var _dataObj$tid$c$num;
                if (dataObj[tid][did].unauth) {
                  unauth = true;
                }
                if (c.num ? (_dataObj$tid$c$num = dataObj[tid][c.num]) === null || _dataObj$tid$c$num === void 0 ? void 0 : _dataObj$tid$c$num.v : true) {
                  return true;
                } else {
                  return marketCapValues.includes(c.value);
                }
              }
              return false;
            } catch (error) {
              return false;
            }
          }).length > 0;
        } else {
          // check for TEV and marketcap?
          push = true;
        }
        if (unauth) {
          c.unauth = true;
        }
        if (push) {
          acc.push(c);
        }
        return acc;
      }, []);
      return this.hidePrice ? columnsWithData.filter(function (f) {
        var priceToHide = ["lastPrice", "priceTime", "changePercent", "val-float-100161", "val-float-marketcap", "val-float-tev"];
        return !priceToHide.includes(f.value);
      }) : columnsWithData;
    },
    aggList: function aggList() {
      var _this2 = this; /**
                         * Back up bobby.
                         * you have a list of tickers, each with a value
                         * for each one of those keys
                         */ // map the baseAggList name key to the method function name
      var keysToIgnore = ["name"]; // keysToAgg is all based on the "value" field and
      // what field is created and calculated on each item in the table
      var keysToAgg = this.aggColumns.map(function (m) {
        return m.value;
      }).filter(function (f) {
        return !keysToIgnore.includes(f);
      });
      var list = this.list.tickers ? this.list.tickers : []; // array of ticker values
      var aggMap = {
        Mean: "mean",
        Median: "median",
        High: "max",
        Low: "min",
        "Standard Deviation": "stdev",
        Count: "count"
      };
      var aggList = this.baseAggList.map(function (baseAgg) {
        // baseAgg is an object.. I need to add the appropriate keys to it
        // aggFn is method local to this component using lodash to calculate stuff
        var aggFn = _this2[aggMap[baseAgg.name]]; // Keys to Agg is a list of the column keys
        return keysToAgg.reduce(function (acc, dataKey) {
          // what if it is 0?
          var toAggregate = list.filter(function (af) {
            return af[dataKey];
          }).map(function (am) {
            return am[dataKey];
          }); // to aggregate is a list of the values for
          // a dataKey where the dataKey is the "value"
          // on a column header
          acc[dataKey] = aggFn(toAggregate);
          return acc;
        }, baseAgg);
      });
      return this.addActiveSymbolToArray(aggList);
    }
  },
  methods: {
    addActiveSymbolToArray: function addActiveSymbolToArray() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var activeSymbol = this.activeCompObj;
      if (Object.keys(activeSymbol).length > 0) {
        return [activeSymbol].concat(arr);
      }
      return arr;
    },
    mean: function mean() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return _mean(arr);
    },
    median: function median() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return _median(arr);
    },
    max: function max() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return _max(arr);
    },
    min: function min() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return _min(arr);
    },
    stdev: function stdev() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return deviation(arr);
    },
    count: function count() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return arr.length;
    }
  }
});